import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import store from './store/store';
import { Config } from './Config';
import { BrowserRouter } from 'react-router-dom';
import { facebook } from './Services/Facebook';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ReactTagManager } from 'react-gtm-ts';
import ErrorBoundaryProvider from './Views/ErrorBoundary/ErrorBoundaryProvider';

facebook.Init();

if (Config.gtmTag) {
    ReactTagManager.init({
        code: Config.gtmTag,
        debug: true, // debug mode (default false)
    });
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ErrorBoundaryProvider>
        <Provider store={store}>
            <GoogleOAuthProvider clientId={Config.googleClientId}>
                <GoogleReCaptchaProvider
                    reCaptchaKey='6LeODs8oAAAAAAVLMFnQQ1980PdxjMcJhKSJta0j'
                    useEnterprise={true}
                    scriptProps={{
                        async: false,
                        defer: false,
                        appendTo: 'head',
                        nonce: undefined,
                    }}
                >
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </GoogleReCaptchaProvider>
            </GoogleOAuthProvider>
        </Provider>
    </ErrorBoundaryProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
