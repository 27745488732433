export const INDUSTRIES = {
    'professional-services': {
        industryId: 'professional-services',
        displayName: 'Professional Services',
        description: 'Professional services.',
    },
    'real-estate': {
        industryId: 'real-estate',
        displayName: 'Real Estate',
        description:
            'Buying and Selling of residential and commercial properties.',
    },
    'healthcare-and-lifesciences': {
        industryId: 'healthcare-and-lifesciences',
        displayName: 'Healthcare and Life Sciences',
        description:
            'Healthcare services, including hospital, health insurances.',
    },
    'human-resources-staffing-recruitment': {
        industryId: 'human-resources-staffing-recruitment',
        displayName: 'HR, Staffing or Recruitment',
        description: 'Human resources, staffing, or recruitment.',
    },
    'energy-and-utilities': {
        industryId: 'energy-and-utilities',
        displayName: 'Energy and Utilities',
        description: 'Energy and Utilities.',
    },
    entertainment: {
        industryId: 'entertainment',
        displayName: 'Entertainment',
        description: 'Entertainment, including gaming and dating services.',
    },
    'retail-and-consumer-products': {
        industryId: 'retail-and-consumer-products',
        displayName: 'Retail and Consumer Products',
        description:
            'Consumer products and services, including Retails and eTails.',
    },
    'transportation-logistics': {
        industryId: 'transportation-logistics',
        displayName: 'Transportation or Logistics',
        description: 'Transportation or logistics services.',
    },
    agriculture: {
        industryId: 'agriculture',
        displayName: 'Agriculture',
        description: 'Agriculture businesses and services.',
    },
    insurance: {
        industryId: 'insurance',
        displayName: 'Insurance',
        description: 'Insurance products and services',
    },
    'postal-delivery': {
        industryId: 'postal-delivery',
        displayName: 'Postal and Delivery',
        description: 'Postal and delivery services.',
    },
    education: {
        industryId: 'education',
        displayName: 'Education',
        description:
            'Educational institutions providing education to students in educational settings.',
    },
    hospitality: {
        industryId: 'hospitality',
        displayName: 'Hospitality and Travel',
        description: 'Travel, hospitality and transportation.',
    },
    'financial-services': {
        industryId: 'financial-services',
        displayName: 'Financial Services',
        description: 'Banking and financial institution.',
    },
    political: {
        industryId: 'political',
        displayName: 'Political',
        description: 'Political campaigns and services.',
    },
    'gambling-and-lottery': {
        industryId: 'gambling-and-lottery',
        displayName: 'Gambling and Lottery',
        description: 'Gambling and Lotteries.',
    },
    legal: {
        industryId: 'legal',
        displayName: 'Legal',
        description: 'Legal services and law firms.',
    },
    'construction-and-materials': {
        industryId: 'construction-and-materials',
        displayName: 'Construction, Materials, and Trade Services',
        description: 'Construction, materials, and trade services.',
    },
    'non-profit-organization': {
        industryId: 'non-profit-organization',
        displayName: 'Non-profit Organization',
        description: 'Non-profit organization with valid tax-exempted status.',
    },
    manufacturing: {
        industryId: 'manufacturing',
        displayName: 'Manufacturing',
        description: 'Goods producing industries.',
    },
    'public-sector': {
        industryId: 'public-sector',
        displayName: 'Government Services and Agencies',
        description: 'Local, state or federal agencies, utilities.',
    },
    'information-technology-services': {
        industryId: 'information-technology-services',
        displayName: 'Information Technology Services',
        description: 'Information technology and information services.',
    },
    'mass-media-and-communication': {
        industryId: 'mass-media-and-communication',
        displayName: 'Media and Communication',
        description: 'Communication and mass media.',
    },
};

export const LQ_INTEGRATIONS = {
    google: 'Google',
    facebook: 'Facebook',
    linkedin: 'LinkedIn',
    salesforce: 'Salesforce',
    'dynamics-365': 'Dynamics 365',
    wordpress: 'Wordpress',
    other: 'Other'
};
