import { FormikErrors, useFormik } from 'formik';
import FormElement from '../Common/Forms/FormElement';
import Input from '../Common/Forms/Input';
import Button from '../Common/Button';
import './AdditionalDataForm.scss';
import { NotifyError, NotifySuccess } from '../../Services/Notify';
import { ChangeEventHandler, useEffect } from 'react';
import { useGlobalLoading } from '../../hooks';
import FormikSelect from '../Common/Forms/FormikSelect';
import { validateUrl } from '../../Services/Helper';
import CheckboxGroup from '../Common/Forms/CheckboxGroup';
import { StoreUserAdditionalData } from '../../Services/Login';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { INDUSTRIES, LQ_INTEGRATIONS } from '../../constants/common';

type AdditionalDataFormValues = {
    numberOfLeads: number | undefined;
    website: string | undefined;
    industry: string | undefined;
    currentCrm: string | undefined;
    topLeadSources: string[] | undefined;
    otherLeadSources?: string | undefined;
};

const integrationOptions = Object.entries(LQ_INTEGRATIONS).map((x) => ({
    value: x[0],
    label: x[1],
}));

const MAX_NUMBER_OF_LEADS = 10_000_000_000;

const AdditionalDataForm = () => {
    const { setLoading } = useGlobalLoading();
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.user.user);
    const industries = Object.values(INDUSTRIES || []).map((x) => ({
        value: x.industryId,
        label: x.displayName,
    }));

    const validate = (values: AdditionalDataFormValues) => {
        const errors: FormikErrors<AdditionalDataFormValues> = {};

        if (!values.industry) {
            errors.industry = 'Industry is required';
        }

        if (!values.numberOfLeads) {
            errors.numberOfLeads = 'Number of leads is required';
        }

        if (values.website && !validateUrl(values.website)) {
            errors.website = 'Website contains invalid url';
        }

        if (
            values.topLeadSources?.includes('other') &&
            !values.otherLeadSources
        ) {
            errors.otherLeadSources = 'Enter lead source';
        }

        return errors;
    };

    const onSubmit = async (values: AdditionalDataFormValues) => {
        if (!values.industry || !values.numberOfLeads) {
            return;
        }

        try {
            setLoading(true);

            const data = {
                ...values,
                userId: user.id,
                numberOfLeads: values.numberOfLeads || 0,
                industry: values.industry || '',
            };
            await StoreUserAdditionalData(data);
            NotifySuccess('Additional data saved.');
            navigate('/dashboard');
        } catch (error) {
            if (error instanceof Error) {
                NotifyError(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik<AdditionalDataFormValues>({
        initialValues: {
            numberOfLeads: undefined,
            website: undefined,
            industry: undefined,
            currentCrm: undefined,
            topLeadSources: [],
            otherLeadSources: undefined,
        },
        validate,
        onSubmit,
    });

    const onNumberOfLeadsChange: ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        if (event.target.value === '-') {
            return;
        }

        if (event.target.valueAsNumber > MAX_NUMBER_OF_LEADS) {
            return;
        }

        formik.setFieldValue(
            'numberOfLeads',
            Math.abs(event.target.valueAsNumber)
        );
    };

    useEffect(() => {
        if (!user?.id) {
            navigate('/login');
        }
    }, [user]);

    return (
        <div className='additionalDataForm'>
            <h2 className='additionalDataForm__title'>Additional data</h2>
            <h3 className='additionalDataForm__subtitle'>
                Please fill the fields
            </h3>
            <form onSubmit={formik.handleSubmit}>
                <FormElement
                    label='Number of leads'
                    touched={formik.touched.numberOfLeads}
                    error={formik.errors.numberOfLeads}
                >
                    <Input
                        type='number'
                        name='numberOfLeads'
                        onChange={onNumberOfLeadsChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.numberOfLeads}
                        min={0}
                        max={MAX_NUMBER_OF_LEADS}
                    />
                </FormElement>
                <FormElement
                    label='Website'
                    touched={formik.touched.website}
                    error={formik.errors.website}
                >
                    <Input
                        type='url'
                        name='website'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.website}
                    />
                </FormElement>
                <FormElement
                    label='Industry'
                    touched={formik.touched.industry}
                    error={formik.errors.industry}
                >
                    <FormikSelect
                        name='industry'
                        value={formik.values.industry}
                        options={industries}
                        formik={formik}
                    />
                </FormElement>
                <FormElement
                    label='Current CRM'
                    touched={formik.touched.currentCrm}
                    error={formik.errors.currentCrm}
                >
                    <Input
                        type='text'
                        name='currentCrm'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.currentCrm}
                    />
                </FormElement>
                <FormElement
                    label='Top lead sources'
                    touched={formik.touched.topLeadSources}
                    error={formik.errors.topLeadSources}
                >
                    <CheckboxGroup
                        formik={formik}
                        name='topLeadSources'
                        options={integrationOptions}
                        value={formik.values.topLeadSources || []}
                        style={{ marginTop: '10px' }}
                    />
                </FormElement>
                {formik.values.topLeadSources?.includes('other') && (
                    <FormElement
                        label='Lead source'
                        touched={formik.touched.otherLeadSources}
                        error={formik.errors.otherLeadSources}
                    >
                        <Input
                            type='text'
                            name='otherLeadSources'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.otherLeadSources}
                        />
                    </FormElement>
                )}
                <Button name='Go to your dashboard' style={{ width: '100%' }} />
            </form>
        </div>
    );
};

export default AdditionalDataForm;
