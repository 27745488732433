import { CSSProperties, PropsWithChildren, ReactNode } from 'react';

import './FormElement.scss';
import ErrorMessage from './ErrorMessage';

type FormElementProps = PropsWithChildren<{
    label?: string | ReactNode;
    touched?: boolean;
    error?: string;
    style?: CSSProperties;
    classNames?: string;
    flexCenter?: boolean;
    bottomBorder?: boolean;
    disabled?: boolean;
    description?: ReactNode;
    extraRight?: ReactNode;
    inline?: boolean;
}>;

export const FormElement = (props: FormElementProps) => {
    return (
        <div
            className={`formElement ${props.classNames || ''} ${
                props?.bottomBorder ? 'formElement--bottomBorder' : ''
            } ${props?.disabled ? 'formElement--disabled' : ''} ${
                props?.inline ? 'formElement--inline' : ''
            }`}
            style={props.style}
        >
            <div className='formElement__labelWrapper'>
                <label className='formElement__label'>{props.label}</label>
                {props.extraRight}
            </div>
            <div className='formElement__description'>{props.description}</div>
            <div
                className={`formElement__input ${
                    props.flexCenter ? 'formElement__input--flexCenter' : ''
                }`}
            >
                {props.children}

                {props.touched && props.error && (
                    <ErrorMessage error={props.error} />
                )}
            </div>
        </div>
    );
};

export default FormElement;
