import logo from '../../assets/img/logo.png';
import './Logo.scss';

const Logo = () => {
    return (
        <header className='logo'>
            <div className='logo__container'>
                <div className='logo__top'>
                    <div className='logo__logo'>
                        <a href='/'>
                            <img src={logo} alt='Lead Qualifier logo' />
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Logo;
