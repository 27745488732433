import { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';
import { LogFrontendError } from '../../Services/LogsService';

const ErrorBoundaryProvider = (props: PropsWithChildren) => {
    const onError: ErrorBoundary['props']['onError'] = async (
        error,
        { componentStack }
    ) => {
        try {
            await LogFrontendError({
                message: error?.message,
                stack: error?.stack,
                name: error?.name,
                cause: error?.cause,
                componentStack,
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <ErrorBoundary
            FallbackComponent={ErrorBoundaryFallback}
            onError={onError}
        >
            {props?.children}
        </ErrorBoundary>
    );
};

export default ErrorBoundaryProvider;
