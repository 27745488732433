import React, { CSSProperties } from 'react';

import './ErrorMessage.scss';

type ErrorMessageProps = {
    error: string;
    hideOverflow?: boolean;
    style?: CSSProperties;
};

const ErrorMessage = (props: ErrorMessageProps) => {
    return (
        <div className='errorMessage'>
            <p
                className={`errorMessage__text ${
                    props?.hideOverflow
                        ? 'errorMessage__text--hideOverflow'
                        : ''
                }`}
                style={props?.style}
            >
                {props.error}
            </p>
        </div>
    );
};

export default ErrorMessage;
