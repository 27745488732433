import moment from 'moment';
import {
    EditDetailsFormValues,
    Subscriber,
} from '../Views/Admin/Subscribers/types';
import { MIME_TYPES } from '../constants/file';

const generalDateTimeFormat = 'MMM Do, YYYY, h:mm a';
const NORMALIZE_PHONE_NUMBER_REGEX = /[^0-9]/g;

export const capitalizeFirstLetter = (string?: string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

export const FormatDateGeneral = (string?: string) => {
    return string ? moment(string).format('MMM Do, YYYY') : '';
};

export const FormatDateTimeGeneral = (string?: string) => {
    return string ? moment(string).format(generalDateTimeFormat) : '';
};

export const FormatDateTimeGeneralFromUtc = (
    string?: string,
    format?: string
) => {
    return string
        ? moment
              .utc(string)
              .local()
              .format(format || generalDateTimeFormat)
        : '';
};

export const FormatDateTimeGeneralFromUnix = (string?: number) => {
    return string
        ? moment.unix(string).local().format(generalDateTimeFormat)
        : '';
};

export const getFormattedPhoneNum = (input: string) => {
    const onlyNumbers = input.replace(/\D/g, '');

    if (onlyNumbers.length < 10) {
        return input;
    }

    const last4 = onlyNumbers.slice(-4);
    const first3 = onlyNumbers.slice(-7, -4);
    const townCode = onlyNumbers.slice(-10, -7);
    const countryCode = onlyNumbers.slice(0, -10);

    const reginalPhone = `(${townCode}) ${first3}-${last4}`;

    if (countryCode) {
        return `+${countryCode} ${reginalPhone}`;
    } else {
        return `+1 ${reginalPhone}`;
    }
};

export const getTimeRanges = (
    interval: number,
    language = window.navigator.language
) => {
    const ranges = [];
    const date = new Date();
    const format = {
        hour: 'numeric',
        minute: 'numeric',
    } as const;
    for (let minutes = 0; minutes < 24 * 60; minutes = minutes + interval) {
        date.setHours(0);
        date.setMinutes(minutes);
        ranges.push(date.toLocaleTimeString(language, format));
    }
    return ranges;
};

export const validEmail = (value: string) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
};

export const validPhone = (value: string) => {
    return /^[+0-9]+$/i.test(value);
};

export const normalizePhoneNumber = (value: string | undefined) =>
    value ? `+${value?.replaceAll(NORMALIZE_PHONE_NUMBER_REGEX, '')}` : '';

export const DateCompare = (a: string, b: string) => {
    const diff = moment(b).diff(moment(a));

    if ((a && !b) || diff < 0) {
        return -1;
    }
    if ((b && !a) || diff > 0) {
        return 1;
    }

    return 0;
};

export const StringCompareIgnoreCase = (a: string, b: string) => {
    const strA = a.toUpperCase();
    const strB = b.toUpperCase();
    if (strA < strB) {
        return -1;
    }
    if (strA > strB) {
        return 1;
    }

    return 0;
};

export const ComposeFullName = (firstName?: string, secondName?: string) => {
    return [firstName, secondName].join(' ').trim();
};

export const GetMapKeyByValue = <TKey, TValue>(
    map: Map<TKey, TValue>,
    value: TValue
): TKey | undefined => {
    const entry = [...(map.entries() || [])].find((x) => x[1] === value);

    if (entry) {
        return entry[0];
    }
};

export const StripHtmlTags = (s: string): string => {
    return s
        .replaceAll(/<head\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/head>/gi, '')
        .replaceAll(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
        .replaceAll(/<style\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/style>/gi, '')
        .replaceAll(/<\/?[^>]+(>|$)/gi, '');
};

export const constructSubscriberEditData = (
    subscriber: Subscriber,
    formValues: EditDetailsFormValues
) => {
    const name = `${formValues.firstName + ' ' || ''}${
        formValues.lastName || ''
    }`;

    const requestData: EditDetailsFormValues = {
        id: subscriber.id,
    };

    if (name && subscriber?.name !== name) {
        requestData['name'] = name;
    }

    if (formValues.phone && subscriber?.Phone !== formValues.phone) {
        requestData['phone'] = formValues.phone;
    }

    if (formValues.line1) {
        requestData['line1'] = formValues.line1;
        if (subscriber?.billing_address?.line1 !== formValues.line1) {
            requestData['isBillingAddressUpdated'] = true;
        }
    }

    if (formValues.line2) {
        requestData['line2'] = formValues.line2;
        if (subscriber?.billing_address?.line2 !== formValues.line2) {
            requestData['isBillingAddressUpdated'] = true;
        }
    }

    if (formValues.city) {
        requestData['city'] = formValues.city;
        if (subscriber?.billing_address?.city !== formValues.city) {
            requestData['isBillingAddressUpdated'] = true;
        }
    }

    if (formValues.state) {
        requestData['state'] = formValues.state;
        if (subscriber?.billing_address?.state !== formValues.state) {
            requestData['isBillingAddressUpdated'] = true;
        }
    }

    if (formValues.postal_code) {
        requestData['postal_code'] = formValues.postal_code;
        if (
            subscriber?.billing_address?.postal_code !== formValues.postal_code
        ) {
            requestData['isBillingAddressUpdated'] = true;
        }
    }

    if (formValues.country) {
        requestData['country'] = formValues.country;
        if (subscriber?.billing_address?.country !== formValues.country) {
            requestData['isBillingAddressUpdated'] = true;
        }
    }

    return requestData;
};

export const validateUrl = (value: string) => {
    const urlRegEx = new RegExp(
        '^([a-zA-Z]+:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i'
    );

    return urlRegEx.test(value);
};

export const WrapText = (value: string, length: number) => {
    const words = value.split(' ');

    let i = 0;
    const lines = [];
    let buffer = '';
    while (i < words.length) {
        if (buffer.length + words[i].length >= length) {
            lines.push(buffer);
            buffer = '';
        }

        buffer += ' ' + words[i];
        i++;
    }

    lines.push(buffer);

    return lines;
};

export const getMonthsFromRange = (
    startDate: string,
    endDate: string,
    format = 'MMM, YY'
) => {
    const betweenMonths = [];
    const start = moment(startDate);
    const end = moment(endDate);

    if (start < end) {
        const date = start.startOf('month');

        while (date < end.endOf('month')) {
            betweenMonths.push(date.format(format));
            date.add(1, 'month');
        }
    }

    return betweenMonths;
};

export const sleep = async (milliseconds: number) => {
    return new Promise((r) => setTimeout(r, milliseconds));
};

export const parseFileName = (fileName: string, mime: string) => {
    if (!(mime in MIME_TYPES)) {
        return [fileName];
    }

    const extensions = MIME_TYPES[mime];

    if (extensions.length === 1) {
        const nameWithoutExt = fileName.replace(`.${extensions[0]}`, '');
        return [nameWithoutExt, extensions[0]];
    }

    let currentExtension = undefined;

    extensions.forEach((el) => {
        if (fileName.includes(`.${el}`)) {
            currentExtension = el;
        }
    });

    if (!currentExtension) {
        return [fileName];
    }

    const nameWithoutExt = fileName.replace(`.${currentExtension}`, '');

    return [nameWithoutExt, currentExtension];
};
