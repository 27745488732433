import Axios, { AxiosError } from 'axios';
import { PostApi } from './ApiServices';
import { saveData } from './Auth';
import {
    RequestLoginUserData,
    RequestRegisterUserData,
    User,
} from '../Views/Auth/types';
import { GetSingleResponse, LoginResponse, SocialLoginData } from './type';
import { TokenResponse } from '@react-oauth/google';

export const login = async (
    values: RequestLoginUserData,
    isAdmin = false
): Promise<GetSingleResponse<LoginResponse> | undefined> => {
    try {
        const urlPath = isAdmin ? '/admin/login' : 'login';
        const response = await PostApi(urlPath, values);
        const res = response?.data;
        if (res.status === 200) {
            if (
                isAdmin ||
                (res.data?.user?.numberOfLeads && res.data?.user?.industry)
            ) {
                saveData(res.data, values.rememberMe, isAdmin);
            }

            return res;
        } else {
            throw new Error(res.message);
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            throw new Error(
                e.response
                    ? e.response.data.message
                    : e.message
                    ? e.message
                    : 'Network error'
            );
        } else {
            throw e;
        }
    }
};

export const loginGoogle = async (
    codeResponse: Omit<
        TokenResponse,
        'error' | 'error_description' | 'error_uri'
    >
) => {
    const responce = await Axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        {
            headers: {
                Authorization: `Bearer ${codeResponse.access_token}`,
                Accept: 'application/json',
            },
        }
    );

    const values = {
        name: responce.data.name,
        email: responce.data.email,
        googleId: responce.data.id,
        imageUrl: responce.data.picture,
    };

    return loginSocial(values, 'google');
};

export const loginFacebook = async (values: SocialLoginData) => {
    return loginSocial(values, 'facebook');
};

export const registerNewUser = async (
    values: RequestRegisterUserData
): Promise<GetSingleResponse<LoginResponse> | undefined> => {
    try {
        const response = await PostApi('register', values);
        const res = response?.data;
        if (res.status === 200) {
            return res;
        } else {
            throw new Error(res.message);
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            throw new Error(
                e.response
                    ? e.response.data.message
                    : e.message
                    ? e.message
                    : 'Network error'
            );
        } else {
            throw e;
        }
    }
};

export const forgotPasswordRequest = async (values: {
    email: string;
}): Promise<GetSingleResponse | undefined> => {
    try {
        const response = await PostApi('forgot-password', values);
        const res = response?.data;
        if (res.status === 200) {
            return res;
        } else {
            throw new Error(res.message);
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            throw new Error(
                e.response
                    ? e.response.data.message
                    : e.message
                    ? e.message
                    : 'Network error'
            );
        } else {
            throw e;
        }
    }
};

export const resetPassword = async (values: {
    password: string;
    confirmPassword: string;
    email: string | null;
    token: string | null;
}): Promise<GetSingleResponse | undefined> => {
    try {
        const response = await PostApi('reset-password', values);
        const res = response?.data;
        if (res.status === 200) {
            return res;
        } else {
            throw new Error(res.message);
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            throw new Error(
                e.response
                    ? e.response.data.message
                    : e.message
                    ? e.message
                    : 'Network error'
            );
        } else {
            throw e;
        }
    }
};

const loginSocial = async (
    values: SocialLoginData,
    network: string
): Promise<GetSingleResponse<LoginResponse> | undefined> => {
    try {
        const response = await PostApi(`social-login/${network}`, values);
        const res = response?.data;
        if (res.status === 200) {
            saveData(res.data);
            return res;
        } else {
            throw new Error(res.message);
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            throw new Error(
                e.response
                    ? e.response.data.message
                    : e.message
                    ? e.message
                    : 'Network error'
            );
        } else {
            throw e;
        }
    }
};

export const StoreUserAdditionalData = async (data: {
    numberOfLeads: number;
    website: string | undefined;
    industry: string;
    currentCrm: string | undefined;
    topLeadSources: string[] | undefined;
    otherLeadSources?: string | undefined;
    userId: User['id'];
}): Promise<GetSingleResponse> => {
    try {
        const response = await PostApi(`profile/additional-data`, data);
        const resData = response?.data;

        if (response?.status !== 200) {
            throw new Error(resData.message);
        }

        saveData(resData?.data);

        return resData;
    } catch (error) {
        if (error instanceof AxiosError || error instanceof Error) {
            throw new Error(error.message);
        } else {
            throw new Error('Error: Network Error!');
        }
    }
};
