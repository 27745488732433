import { AxiosError } from 'axios';
import { PostApi } from './ApiServices';
import { GetSingleResponse } from './type';

export const LogFrontendError = async (
    data: Error & {
        componentStack: string;
    }
): Promise<GetSingleResponse> => {
    try {
        const response = await PostApi('frontend/error-logs', {
            page: window.location.href,
            ...data,
        });
        const resData = response?.data;

        if (response?.status !== 200) {
            throw new Error(resData.message);
        }

        return resData;
    } catch (error) {
        if (error instanceof AxiosError) {
            throw new Error(error.message);
        } else {
            throw new Error('Error: Network Error!');
        }
    }
};
