import { PropsWithChildren } from 'react';
import Logo from './Pages/Logo';

function NoHeaderLayout(props: PropsWithChildren) {
    return (
        <>
            <Logo />
            {props.children}
        </>
    );
}

export default NoHeaderLayout;
