import { CSSProperties, ReactNode } from 'react';
import './Input.scss';

type InputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    noBorder?: boolean;
    extra?: ReactNode;
    icon?: string;
    bgTransparent?: boolean;
    iconRight?: boolean;
    contrastMode?: boolean;
    wrapperStyle?: CSSProperties;
};

const Input = (props: InputProps) => {
    return (
        <div
            className={`input ${props.noBorder ? 'input--noBorder' : ''} ${
                props.bgTransparent ? 'input--bgTransparent' : ''
            } ${props.iconRight ? 'input--iconRight' : ''} ${
                props?.contrastMode ? 'input--contrastMode' : ''
            }`}
            style={props?.wrapperStyle}
        >
            <input
                className={`input__input ${
                    props.icon ? '' : 'input__input--noIcon'
                }`}
                // selected={props.selected}
                type={props.type}
                name={props.name}
                placeholder={
                    props.placeholder ? props.placeholder : 'Start typing…'
                }
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={props.value}
                readOnly={props.readOnly}
                onClick={props.onClick}
                style={props.style}
                maxLength={props.maxLength}
                minLength={props.minLength}
                disabled={props.disabled}
                autoComplete={props?.autoComplete}
            />
            <div className='input__extra'>{props.extra}</div>
            <div className='input__icon'>
                <i className={props.icon}></i>
            </div>
        </div>
    );
};

export default Input;
