import { FormikProps } from 'formik';
import Select, { SelectProps } from './Select';

import './Select.scss';

type FormikSelectProps = Omit<SelectProps, 'onBlur'> & {
    formik?: FormikProps<any>;
};

const FormikSelect = (props: FormikSelectProps) => {
    const handleChange = (value: string | number | undefined) => {
        if (props.formik && props.name && (value || value === 0)) {
            props.formik.setFieldValue(props.name, value);
            return;
        }
    };

    return (
        <Select
            name={props.name}
            value={props.value}
            border={props.border}
            options={props.options}
            emptyOptionLable={props.emptyOptionLable}
            onBlur={props.formik?.handleBlur}
            onChange={handleChange}
            onEmptyOptionsClick={props.onEmptyOptionsClick}
            style={props.style}
            disabled={props.disabled}
            searchable={props?.searchable}
        />
    );
};

export default FormikSelect;
