import { FormikProps } from 'formik';
import Switch from './Switch';
import './CheckboxGroup.scss';
import { CSSProperties } from 'react';

type CheckboxGroupProps = {
    options: { value: number | string; label: string; description?: string }[];
    value: (string | number)[];
    formik: FormikProps<any>;
    name: string;
    style?: CSSProperties;
};

const CheckboxGroup = (props: CheckboxGroupProps) => {
    const onChange = (value: string | number, checked: boolean) => {
        let newValue = undefined;

        if (checked) {
            newValue = [...props.value, value];
        } else {
            newValue = props.value.filter((x) => x !== value);
        }

        props.formik.setFieldValue(props.name, newValue);
    };

    return (
        <div className='checkboxGroup' style={props?.style}>
            {props.options.map((x) => (
                <Switch
                    Tname={props.name}
                    name={x.label}
                    onChange={() =>
                        onChange(x.value, !props.value.includes(x.value))
                    }
                    value={x.value}
                    checked={props.value.includes(x.value)}
                    onBlur={props.formik.handleBlur}
                />
            ))}
        </div>
    );
};

export default CheckboxGroup;
