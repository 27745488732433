import { ComponentType } from 'react';
import { FallbackProps } from 'react-error-boundary';
import NoHeaderLayout from '../../Layouts/NoHeaderLayout';
import './ErrorBoundaryFallback.scss';

const ErrorBoundaryFallback: ComponentType<FallbackProps> = () => {
    return (
        <NoHeaderLayout>
            <div className='errorBoundaryFallback'>
                <h1>Something went wrong</h1>
                <a href='/'>Go back to home page</a>
            </div>
        </NoHeaderLayout>
    );
};

export default ErrorBoundaryFallback;
