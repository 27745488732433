import React, { useEffect, useRef, useState } from 'react';

import './Select.scss';
import { ThreeDots } from 'react-loader-spinner';
import Input from './Input';
import EmptyList from '../EmptyList';

export type SelectOption = {
    value: number | string | undefined;
    label: string | undefined;
    children?: SelectOption[];
    textColor?: React.CSSProperties['color'];
    disabled?: boolean;
};

export type SelectProps = {
    options: SelectOption[];
    border?: boolean;
    name?: string;
    onChange?: (value: string | number) => void;
    onBlur?: React.DOMAttributes<HTMLDivElement>['onBlur'];
    value?: string | number;
    emptyOptionLable?: string;
    hideBottomBorder?: boolean;
    style?: React.CSSProperties;
    whiteArrow?: boolean;
    loading?: boolean;
    onEmptyOptionsClick?: () => void;
    disabled?: boolean;
    notSelectable?: boolean;
    searchable?: boolean;
};

const Select = (props: SelectProps) => {
    const [opened, setOpened] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedValue, setSelectedValue] = useState<
        string | number | undefined | null
    >(undefined);
    const [isUp, setIsUp] = useState(false);
    const divRef = useRef<HTMLDivElement | null>(null);
    const listRef = useRef<HTMLUListElement | null>(null);
    const selectRef = useRef<HTMLDivElement | null>(null);

    const displayedOptions = props?.searchable
        ? props.options.filter((option) =>
              option.label
                  ?.toLocaleLowerCase()
                  ?.includes(search.toLocaleLowerCase().trim())
          )
        : props.options;

    let selectedOption = props.options.find(
        (v) =>
            v.value === selectedValue ||
            (v.children && v.children.find((x) => x.value === selectedValue))
    );

    if (selectedOption?.children) {
        selectedOption = selectedOption.children.find(
            (x) => x.value === selectedValue
        );
    }

    const validateDisplayedSearchValue = () => {
        if (props?.searchable && !opened) {
            setSearch(selectedOption?.label || '');
        }
    };

    const handleToggle = (state?: boolean, skipEmptyOptionsClick = false) => {
        if (props?.notSelectable) {
            return;
        }

        const newOpened = !opened;
        if (
            !skipEmptyOptionsClick &&
            props?.onEmptyOptionsClick &&
            !props.options.length
        ) {
            props.onEmptyOptionsClick();
        }
        setOpened(state !== undefined ? state : newOpened);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (
            divRef.current &&
            !divRef.current.contains(event.target as Node) &&
            listRef.current &&
            !listRef.current.contains(event.target as Node)
        ) {
            handleToggle(false, true);
            validateDisplayedSearchValue();
        }
    };

    const handleChange = (option: SelectOption | undefined) => {
        if (props.onChange) {
            props.onChange(option?.value || 0);
            setSelectedValue(option?.value || 0);
            handleToggle();
            setSearch(option?.label || '');
            return;
        }
    };

    const handleWindowScroll = () => {
        const isUpTemp =
            (divRef.current?.getBoundingClientRect().bottom || 0) >=
            window.innerHeight / 2;
        setIsUp(isUpTemp);
    };

    const handleSearch = (value: string) => {
        setSearch(value);
        handleToggle(true);
    };

    useEffect(() => {
        if (props.value !== selectedValue) {
            setSelectedValue(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        window.addEventListener('click', handleOutsideClick);
        window.addEventListener('scroll', handleWindowScroll);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
            window.removeEventListener('scroll', handleWindowScroll);
        };
    });

    useEffect(() => {
        if (opened) {
            setSearch('');
            handleWindowScroll();
        }
    }, [opened]);

    useEffect(() => {
        validateDisplayedSearchValue();
    }, [opened, selectedValue]);

    return (
        <div className='customSelect' ref={divRef}>
            <div
                id={props.name}
                ref={selectRef}
                className={`customSelect__select ${
                    props?.border ? 'customSelect__select--border' : ''
                } ${
                    props.hideBottomBorder
                        ? 'customSelect__select--noBottomBorder'
                        : ''
                } ${
                    props.whiteArrow ? 'customSelect__select--whiteArrow' : ''
                } ${props.disabled ? 'customSelect__select--disabled' : ''} ${
                    props?.notSelectable
                        ? 'customSelect__select--notSelectable'
                        : ''
                }`}
                onClick={() => handleToggle()}
                onBlur={props.onBlur}
                style={props.style}
                tabIndex={0}
            >
                {props?.loading && (
                    <ThreeDots
                        height='40'
                        width='40'
                        radius='9'
                        color='#fcd00b'
                        ariaLabel='three-dots-loading'
                        visible={true}
                        wrapperStyle={{ justifySelf: 'center' }}
                    />
                )}
                {!props.loading && !props?.searchable && (
                    <span className='customSelect__input'>
                        {selectedOption?.label || (
                            <span className='customSelect__emptyInput'>
                                {props?.emptyOptionLable
                                    ? props.emptyOptionLable
                                    : '---'}
                            </span>
                        )}
                    </span>
                )}
                {!props.loading && props?.searchable && (
                    <Input
                        value={search}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onChange={({ target: { value } }) =>
                            handleSearch(value)
                        }
                        className='customSelect__input'
                        wrapperStyle={{ paddingLeft: '0' }}
                        style={{ width: '100%', height: '50px' }}
                        noBorder
                    />
                )}
            </div>
            <ul
                ref={listRef}
                className={`customSelect__optionList ${
                    opened ? 'customSelect__optionList--opened' : ''
                }`}
                style={isUp ? { bottom: '53px' } : undefined}
            >
                {displayedOptions.length === 0 && (
                    <EmptyList
                        style={{
                            minHeight: '90px',
                            margin: '10px',
                            width: 'auto',
                        }}
                        noBg
                    />
                )}
                {displayedOptions.length > 0 && (
                    <>
                        <li
                            key='empty'
                            onClick={() => handleChange(undefined)}
                            className={
                                selectedValue === 0
                                    ? 'customSelect__option customSelect__option--selected'
                                    : 'customSelect__option'
                            }
                        >
                            <span>
                                {props?.emptyOptionLable
                                    ? props.emptyOptionLable
                                    : '---'}
                            </span>
                        </li>

                        {displayedOptions &&
                            displayedOptions?.map((x) => {
                                if (!x.children) {
                                    return (
                                        <li
                                            key={x.value}
                                            onClick={() =>
                                                !x.disabled && handleChange(x)
                                            }
                                            className={`customSelect__option ${
                                                selectedValue === x.value &&
                                                'customSelect__option--selected'
                                            } ${
                                                x.disabled &&
                                                'customSelect__option--disabled'
                                            }`}
                                        >
                                            <span
                                                style={{
                                                    color: x.textColor
                                                        ? x.textColor
                                                        : '',
                                                }}
                                            >
                                                {x.label}
                                            </span>
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li
                                            key={x.value}
                                            className='customSelect__optionGroup'
                                        >
                                            <span
                                                style={{
                                                    color: x.textColor
                                                        ? x.textColor
                                                        : '',
                                                }}
                                            >
                                                {x.label}
                                            </span>
                                            <ul className='customSelect__optionGroupList'>
                                                {x.children.map((c) => (
                                                    <li
                                                        key={c.value}
                                                        onClick={() =>
                                                            !c.disabled &&
                                                            !x.disabled &&
                                                            handleChange(c)
                                                        }
                                                        className={`customSelect__option ${
                                                            selectedValue ===
                                                                c.value &&
                                                            'customSelect__option--selected'
                                                        } ${
                                                            (c.disabled ||
                                                                x.disabled) &&
                                                            'customSelect__option--disabled'
                                                        }`}
                                                    >
                                                        <span
                                                            style={{
                                                                color: c.textColor
                                                                    ? c.textColor
                                                                    : '',
                                                            }}
                                                        >
                                                            {c.label}
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    );
                                }
                            })}
                    </>
                )}
            </ul>
        </div>
    );
};

export default Select;
