import './Switch.scss';

const Switch = (props) => {
    return (
        <label className='switch' style={props?.style}>
            <input
                className='switch__input'
                type='checkbox'
                name={props.Tname}
                id={props.id}
                checked={props.checked}
                onChange={props.onChange}
                value={props.value}
                onBlur={props.onBlur}
            />
            <div
                className={`switch__content ${
                    props?.bgWhite ? 'switch__content--bgWhite' : ''
                }`}
                style={props?.contentStyle}
            >
                {props.name && <span>{props.name}</span>}{' '}
                {props.description && <div>{props.description}</div>}
            </div>
        </label>
    );
};

export default Switch;

export const SwitchRadio = (props) => {
    return (
        <label className='switch'>
            <input
                className='switch__input'
                id={props.id}
                type='radio'
                name={props.Iname}
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={props.value}
                defaultChecked={props.default}
                checked={props.checked}
            />
            <div className='switch__content switch__content--radio'>
                {props.name && <span>{props.name}</span>}{' '}
                {props.description && <div>{props.description}</div>}
            </div>
        </label>
    );
};

export const SwitchRoundCheckbox = (props) => {
    return (
        <label
            className={`switchRounded ${
                props?.greyDarker ? 'switchRounded--greyDarker' : ''
            }`}
        >
            <input
                className='switchRounded__input'
                type='checkbox'
                defaultChecked={props.defaultChecked}
            />
            <span className='switchRounded__content'>{props.label}</span>
        </label>
    );
};
