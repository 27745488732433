import { CSSProperties } from 'react';
import './EmptyList.scss';

type EmptyListProps = {
    style?: CSSProperties;
    noBg?: boolean;
};

const EmptyList = (props: EmptyListProps) => {
    return (
        <div
            className={`emptyList ${props?.noBg ? 'emptyList--noBg' : ''}`}
            style={props?.style}
        >
            <span className='emptyList__message'>NO RESULTS TO DISPLAY</span>
        </div>
    );
};

export default EmptyList;
